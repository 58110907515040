<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card
          class="transparent pa-1 pa-md-3 pa-lg-6 synthesis-section-border-radius"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 650px;"
                >
                  <h1
                    :class="headerTitleClass"
                    class="synthesis-brand-lightest-blue--text"
                  >
                    Risk management made simple.
                  </h1>

                  <h2
                    :class="headerSubTitleClass"
                    class="synthesis-brand-lightest-blue--text py-6"
                  >
                    Design hedging strategies in real time with live data and stay updated with all market developments.
                  </h2>
                </div>
              </v-col>

              <v-col cols="12">
                <v-btn
                  class="synthesis-brand-dark-blue--text"
                  color="white"
                  depressed
                  height="42"
                  width="110"
                  @click="showContactDialog"
                  v-text="$t('Common.Button.GetStarted')"
                />
              </v-col>

              <v-col cols="12">
                <v-img
                  class="d-inline-flex"
                  height="auto"
                  max-width="800"
                  src="/img/bg/header.png"
                  width="100%"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-blue-02 pa-1 pa-md-3 pa-lg-6 pb-0 pb-md-0 pb-lg-0 synthesis-section-border-radius"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 550px;"
                >
                  <h1
                    :class="sectionTitleClass"
                    class="synthesis-brand-lightest-blue--text"
                  >
                    Secure the future of your business from just one screen
                  </h1>

                  <h2
                    :class="sectionSubTitleClass"
                    class="synthesis-brand-lightest-blue--text py-6"
                  >
                    Get key information on your live exposures, active risk profile and MTM of hedging positions from your dashboard.
                  </h2>
                </div>
              </v-col>

              <v-col
                class="pb-0"
                cols="12"
              >
                <v-img
                  class="d-inline-flex"
                  height="auto"
                  max-width="802"
                  src="/img/bg/section-1.png"
                  width="100%"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="white pa-4 pa-md-8 pa-lg-16 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 465px;"
                >
                  <h1
                    :class="sectionTitleClass"
                    class="synthesis-brand-dark-blue--text"
                  >
                    Simple and Precise Risk and Hedging Reports
                  </h1>

                  <h2
                    :class="sectionSubTitleClass"
                    class="synthesis-brand-dark-blue--text py-6"
                  >
                    Automatically update your current and forecasted exposures, run bespoke risk reports and produce the optimal hedging strategies.
                  </h2>
                </div>
              </v-col>

              <v-col cols="12">
                <v-img
                  class="d-inline-flex"
                  height="auto"
                  max-width="331"
                  src="/img/bg/section-2.png"
                  width="100%"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="synthesis-ui-orange-01 pa-4 pa-md-8 pa-lg-16 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 465px;"
                >
                  <h1
                    :class="sectionTitleClass"
                    class="white--text"
                  >
                    Benchmark against your peers
                  </h1>

                  <h2
                    :class="sectionSubTitleClass"
                    class="white--text py-6"
                  >
                    Compare different hedging approaches across multiple industries and understand how companies hedge within your sector.
                  </h2>
                </div>
              </v-col>

              <v-col cols="12">
                <v-img
                  class="d-inline-flex"
                  height="auto"
                  max-width="507"
                  src="/img/bg/section-3.png"
                  width="100%"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="synthesis-ui-green-01 pa-4 pa-md-8 pa-lg-16 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 460px;"
                >
                  <h1
                    :class="sectionTitleClass"
                    class="white--text"
                  >
                    Don’t get caught unaware during a crisis
                  </h1>

                  <h2
                    :class="sectionSubTitleClass"
                    class="white--text py-6"
                  >
                    Use advanced tools powered by AI to observe your cross asset risk profile in real time and also monitor the live MTM of all your hedge positions.
                  </h2>
                </div>
              </v-col>

              <v-col cols="12">
                <v-img
                  class="d-inline-flex"
                  height="auto"
                  max-width="450"
                  src="/img/bg/section-4.png"
                  width="100%"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="white pa-4 pa-md-8 pa-lg-16 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 485px;"
                >
                  <h1
                    :class="sectionTitleClass"
                    class="synthesis-brand-dark-blue--text"
                  >
                    Stay up to date as market conditions change the profile of your risk
                  </h1>

                  <h2
                    :class="sectionSubTitleClass"
                    class="synthesis-brand-dark-blue--text py-6"
                  >
                    From 24hr alerts to live data and MTM, Synthesis provides clear guidance on hedging risk in real time.
                  </h2>
                </div>
              </v-col>

              <v-col cols="12">
                <v-img
                  class="d-inline-flex"
                  height="auto"
                  max-width="300"
                  src="/img/bg/section-5.png"
                  width="100%"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-blue-02 pa-0 pa-md-8 pa-lg-16 py-0 py-md-0 py-lg-0 synthesis-section-border-radius"
          flat
          height="550"
        >
          <v-img
            height="550"
            src="/img/bg/card-bg.png"
          >
            <v-container
              class="fill-height text-center align-center justify-center"
              fluid
            >
              <v-row>
                <v-col cols="12">
                  <div
                    class="d-inline-block"
                    style="max-width: 780px;"
                  >
                    <h1
                      :class="headerTitleClass"
                      class="synthesis-brand-lightest-blue--text"
                    >
                      Get started today and secure your business.
                    </h1>

                    <h2
                      :class="headerSubTitleClass"
                      class="synthesis-brand-lightest-blue--text py-6"
                    >
                      Speak to a member of our team today to get started with Synthesis and be confident you are on top of your risk during both calm periods and market crises!
                    </h2>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="synthesis-brand-dark-blue--text"
                    color="white"
                    depressed
                    height="42"
                    width="110"
                    @click="showContactDialog"
                    v-text="$t('Common.Button.GetStarted')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta     from '@/mixins/i18n/i18nRouteMeta'
import CssClassFontSizes from '@/mixins/cssClassFontSizes'

export default {
  name      : 'Home',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta, CssClassFontSizes],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    showContactDialog () {
      this.$bus.$emit('Dialog:Contact:Show')
    }
  }
}
</script>

<style scoped>

</style>
