import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Recent Posts")]),_c(VDivider),_c(VList,[_vm._l((_vm.recentArticles),function(article,articleIdx){return [_c(VListItem,{key:("recent-article-" + articleIdx),attrs:{"to":{name: 'PressArticle', params: {slug: article.slug}}}},[(article.featured_media && _vm.getArticleImage(article, 'full'))?_c(VListItemAvatar,{staticClass:"rounded",attrs:{"size":"75","tile":""}},[_c(VImg,{attrs:{"src":_vm.getArticleImage(article, 'full').source_url}})],1):_vm._e(),_c(VListItemContent,{staticClass:"px-0 py-1"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.sanitizeText(article.title.rendered))}}),_c(VListItemSubtitle,{staticClass:"caption mt-2"},[_vm._v(" "+_vm._s(_vm.getArticleDate(article))+" ")])],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }