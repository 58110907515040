<template>
  <v-card flat>
    <v-card-title>Recent Posts</v-card-title>

    <v-divider />

    <v-list>
      <template v-for="(article, articleIdx) in recentArticles">
        <v-list-item
          :key="`recent-article-${articleIdx}`"
          :to="{name: 'PressArticle', params: {slug: article.slug}}"
        >
          <v-list-item-avatar
            v-if="article.featured_media && getArticleImage(article, 'full')"
            class="rounded"
            size="75"
            tile
          >
            <v-img :src="getArticleImage(article, 'full').source_url" />
          </v-list-item-avatar>

          <v-list-item-content class="px-0 py-1">
            <div v-html="sanitizeText(article.title.rendered)" />

            <v-list-item-subtitle class="caption mt-2">
              {{ getArticleDate(article) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>

import i18nRouteMeta     from '@/mixins/i18n/i18nRouteMeta'
import CssClassFontSizes from '@/mixins/cssClassFontSizes'
import PressCommon       from '@/mixins/pressCommon'

export default {
  name      : 'RecentArticles',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta, CssClassFontSizes, PressCommon],
  props     : {
    articlesCount: {
      type   : [Number, String],
      default: 10
    }
  },
  dataStore: {
    PressArticles: 'Press.Articles'
  },
  enums: {},
  data () {
    return {}
  },
  computed: {
    recentArticles () {
      return this.PressArticles?.slice(0, parseInt(this.articlesCount || 10)) || []
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
