<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="text-center"
        cols="12"
      >
        <h1
          :class="headerTitleClass"
          class="synthesis-brand-lightest-blue--text mb-10"
        >
          Press
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-blue-07 pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius"
          color="transparent"
          flat
        >
          <v-container
            class="synthesis-brand-dark-blue--text text-left"
            fluid
          >
            <v-row>
              <v-col
                cols="12"
                lg="6"
                md="8"
                offset-lg="1"
                offset-xl="1"
                xl="7"
              >
                <template v-for="(article, articleIdx) in PressArticles">
                  <v-card
                    :key="`article-${articleIdx}`"
                    :to="{name: 'PressArticle', params: {slug: article.slug}}"
                    class="mb-8 mb-md-16"
                    flat
                  >
                    <v-img
                      v-if="article.featured_media && getArticleImage(article, 'full')"
                      :src="getArticleImage(article, 'full').source_url"
                      max-height="500"
                    />

                    <v-card-text>
                      <v-chip
                        class="ma-4 white--text float-right mt-6"
                        color="synthesis-ui-blue-01"
                        label
                        small
                      >
                        {{ getArticleDate(article) }}
                      </v-chip>

                      <div
                        :class="sectionTitleClass"
                        class="py-4 synthesis-brand-dark-blue--text text-left font-weight-regular"
                        v-html="sanitizeText(article.title.rendered)"
                      />

                      <div
                        :class="sectionSubTitleClass"
                        class="synthesis-brand-dark-blue--text text-left font-weight-regular"
                        v-html="sanitizeText(article.content.rendered)"
                      />
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>

              <v-col
                v-if="$vuetify.breakpoint.mdAndUp"
                lg="4"
                md="4"
                xl="3"
              >
                <recent-articles articles-count="10" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta     from '@/mixins/i18n/i18nRouteMeta'
import CssClassFontSizes from '@/mixins/cssClassFontSizes'
import PressCommon       from '@/mixins/pressCommon'
import RecentArticles    from '@/theme/default/views/press/RecentArticles.vue'

export default {
  name      : 'Press',
  components: { RecentArticles },
  directives: {},
  mixins    : [i18nRouteMeta, CssClassFontSizes, PressCommon],
  props     : {},
  dataStore : {
    PressArticles: 'Press.Articles'
  },
  enums: {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
