import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"synthesis-brand-lightest-blue--text mb-10",class:_vm.headerTitleClass},[_vm._v(" Press ")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"synthesis-ui-blue-07 pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius",attrs:{"color":"transparent","flat":""}},[_c(VContainer,{staticClass:"synthesis-brand-dark-blue--text text-left",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"8","offset-lg":"1","offset-xl":"1","xl":"7"}},[_vm._l((_vm.PressArticles),function(article,articleIdx){return [_c(VCard,{key:("article-" + articleIdx),staticClass:"mb-8 mb-md-16",attrs:{"to":{name: 'PressArticle', params: {slug: article.slug}},"flat":""}},[(article.featured_media && _vm.getArticleImage(article, 'full'))?_c(VImg,{attrs:{"src":_vm.getArticleImage(article, 'full').source_url,"max-height":"500"}}):_vm._e(),_c(VCardText,[_c(VChip,{staticClass:"ma-4 white--text float-right mt-6",attrs:{"color":"synthesis-ui-blue-01","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.getArticleDate(article))+" ")]),_c('div',{staticClass:"py-4 synthesis-brand-dark-blue--text text-left font-weight-regular",class:_vm.sectionTitleClass,domProps:{"innerHTML":_vm._s(_vm.sanitizeText(article.title.rendered))}}),_c('div',{staticClass:"synthesis-brand-dark-blue--text text-left font-weight-regular",class:_vm.sectionSubTitleClass,domProps:{"innerHTML":_vm._s(_vm.sanitizeText(article.content.rendered))}})],1)],1)]})],2),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{attrs:{"lg":"4","md":"4","xl":"3"}},[_c('recent-articles',{attrs:{"articles-count":"10"}})],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }