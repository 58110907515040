<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="text-center"
        cols="12"
      >
        <h1
          :class="headerTitleClass"
          class="synthesis-brand-lightest-blue--text mb-10"
        >
          About Us
        </h1>

        <div class="text-center">
          <img src="/img/award/soe.png">
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-blue-02 pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 700px;"
                >
                  <h1
                    :class="sectionTitleClass"
                    class="synthesis-brand-lightest-blue--text"
                  >
                    What Makes Us Unique
                  </h1>

                  <h2
                    :class="headerSubTitleClass"
                    class="synthesis-brand-lightest-blue--text py-6"
                  >
                    Our people and our technology. From our exceptional board of advisors to the collective experience of senior management and our talented team.
                  </h2>
                </div>
              </v-col>

              <v-col cols="12">
                <v-card
                  class="d-inline-flex"
                  color="transparent"
                  flat
                >
                  <v-img
                    gradient="to bottom, rgba(33, 58, 130, 0) 60%, rgba(33, 58, 130, 1) 95%"
                    height="auto"
                    max-width="1200"
                    src="https://mavenglobal.co.uk/wp-content/uploads/2020/02/business-office.jpg"
                    width="100%"
                  />
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 1200px;"
                >
                  <v-container
                    class="justify-space-between synthesis-brand-lightest-blue--text"
                    fluid
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <div class="px-4">
                          <v-avatar
                            color="synthesis-brand-lightest-blue"
                            size="92"
                          >
                            <v-icon
                              color="synthesis-ui-blue-02"
                              size="56"
                            >
                              mdi-head-flash-outline
                            </v-icon>
                          </v-avatar>

                          <div
                            :class="sectionSubTitleClass"
                            class="font-weight-bold py-4"
                          >
                            Dynamic Innovation
                          </div>

                          <div>Maven’s proprietary models and risk frameworks utilize latest technologies including AI and Machine Learning</div>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="4"
                      >
                        <div class="px-4">
                          <v-avatar
                            color="synthesis-brand-lightest-blue"
                            size="92"
                          >
                            <v-icon
                              color="synthesis-ui-blue-02"
                              size="56"
                            >
                              mdi-web
                            </v-icon>
                          </v-avatar>

                          <div
                            :class="sectionSubTitleClass"
                            class="font-weight-bold py-4"
                          >
                            Global Reach
                          </div>

                          <div>Maven projects span 5 continents<br>and 47 countries</div>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="4"
                      >
                        <div class="px-4">
                          <v-avatar
                            color="synthesis-brand-lightest-blue"
                            size="92"
                          >
                            <v-icon
                              color="synthesis-ui-blue-02"
                              size="56"
                            >
                              mdi-image-filter-center-focus-weak
                            </v-icon>
                          </v-avatar>

                          <div
                            :class="sectionSubTitleClass"
                            class="font-weight-bold py-4"
                          >
                            Client Focus
                          </div>

                          <div>Whether a Government, a Corporation or a Private Capital Fund, Maven clients stay with us for the long term</div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-green-01 pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="synthesis-brand-dark-blue--text text-left"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <h1
                  :class="sectionTitleClass"
                  class="white--text mb-8"
                >
                  Awards won by Maven team members over the years
                </h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="text-center text-lg-left"
                cols="12"
                lg="5"
                md="7"
                offset-lg="2"
              >
                <v-list
                  class="pa-0 d-inline-block"
                  color="transparent"
                  dark
                  two-line
                >
                  <template v-for="(award, awardIdx) in awards">
                    <v-list-item
                      :key="`award-${awardIdx}`"
                      class="px-0"
                    >
                      <v-list-item-avatar>
                        <v-icon x-large>
                          mdi-seal-variant
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <div
                          :class="sectionSubTitleClass"
                          class="text-start"
                        >
                          {{ award.Title }}
                        </div>

                        <v-list-item-subtitle class="text-start">
                          {{ award.Subtitle }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>

              <v-col
                class="text-center text-lg-left"
                cols="12"
                lg="4"
                md="5"
              >
                <v-card
                  class="d-inline-flex"
                  color="transparent"
                  flat
                >
                  <v-img
                    height="auto"
                    max-width="525"
                    src="https://mavenglobal.co.uk/wp-content/uploads/2019/09/MavenGlobal-215.jpg"
                    width="100%"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-ui-blue-05 pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="synthesis-brand-dark-blue--text text-left"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <h1
                  :class="sectionTitleClass"
                  class="white--text mb-8"
                >
                  Management and Advisors
                </h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="8"
                offset-md="2"
              >
                <v-list
                  class="pa-0"
                  color="transparent"
                  dark
                  two-line
                >
                  <template v-for="(member, memberIdx) in members">
                    <v-list-item :key="`member-${memberIdx}`">
                      <v-list-item-avatar
                        v-if="!$vuetify.breakpoint.xsOnly"
                        class="rounded align-self-start"
                        size="150"
                        tile
                      >
                        <v-img :src="member.Image" />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          :class="sectionSubTitleClass"
                          class="text-start"
                        >
                          {{ member.Title }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          {{ member.Subtitle }}
                        </v-list-item-subtitle>

                        <div v-if="$vuetify.breakpoint.xsOnly">
                          <v-avatar
                            class="rounded align-self-start mt-4"
                            size="200"
                            tile
                          >
                            <v-img
                              :src="member.Image"
                            />
                          </v-avatar>
                        </div>

                        <div
                          class="mt-4 text-justify"
                          v-html="member.Body"
                        />
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      v-if="memberIdx < members.length - 1"
                      :key="`member-divider-${memberIdx}`"
                      class="mb-8 mt-4"
                    />
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="synthesis-brand-lightest-blue pa-4 pa-md-8 pa-lg-8 synthesis-section-border-radius fill-height"
          flat
        >
          <v-container
            class="text-center"
            fluid
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="d-inline-block"
                  style="max-width: 1200px;"
                >
                  <v-container
                    class="synthesis-brand-dark-blue--text text-left"
                    fluid
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="5"
                      >
                        <div>
                          <div
                            :class="sectionTitleClass"
                            class="py-4 text-left"
                          >
                            Social Impact
                          </div>

                          <div>A key element of Maven Global’s mission is social impact. Whether we work with development funds on helping local economies or global charities to most efficiently distribute international donations, it is important to for us to feel we are making a small difference.</div>
                        </div>

                        <v-divider class="mt-6 hidden-md-and-up" />
                      </v-col>

                      <v-col
                        cols="12"
                        md="5"
                        offset-md="2"
                      >
                        <div class="float-right">
                          <p>
                            <strong class="maven_term">Maven</strong> (/meɪv(ə)n/) n.<br>
                            INFORMAL•NORTH AMERICAN<br>
                            noun: maven; plural noun: mavens
                          </p>
                          <p>
                            A&nbsp;<strong>maven</strong>&nbsp;is a trusted&nbsp;<a href="https://en.wikipedia.org/wiki/Expert">expert</a>&nbsp;in a particular field, who seeks to pass timely and relevant knowledge on to others in the respective field. The word itself is a borrowing from the&nbsp;<a href="https://en.wikipedia.org/wiki/Yiddish_language">Yiddish</a>&nbsp;<em>meyvn</em>&nbsp;‘an expert, connoisseur’, derived from the&nbsp;<a href="https://en.wikipedia.org/wiki/Hebrew_language">Hebrew</a>&nbsp;<span>מבין</span>&nbsp;<em>mēvīn</em>&nbsp;‘person with understanding, teacher.
                          </p>
                          <p>
                            <strong style="text-decoration: underline;">Synonyms:</strong> ace, adept, champion, genius, hotshot, mavin, sensation, star, superstar, virtuoso, wizard
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta     from '@/mixins/i18n/i18nRouteMeta'
import CssClassFontSizes from '@/mixins/cssClassFontSizes'

export default {
  name      : 'About',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta, CssClassFontSizes],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {
      awards: [
        {
          Title   : 'European Seal of Excellence',
          Subtitle: 'European Commission'
        },
        {
          Title   : 'Best FX Derivatives House of the Year',
          Subtitle: 'Euromoney'
        },
        {
          Title   : 'Currency Derivatives House of the Year',
          Subtitle: 'Risk Magazine'
        },
        {
          Title   : 'Top FX Structuring Team',
          Subtitle: 'Structured Products Magazine'
        },
        {
          Title   : 'Leading Structured Products Team',
          Subtitle: 'MTN-i'
        },
        {
          Title   : 'Best Euro MTN House for FX Linked Structures',
          Subtitle: 'EuroWeek'
        }
      ],

      members: [
        {
          Title   : 'PHILIPPOS KASSIMATIS',
          Subtitle: 'Director',
          Image   : '/img/about/philipos_kassimatis-300x300.jpg',
          Body    : `
              <p>Philippos is the co-founder of Maven Global. He is also the co-founder of Panthir Capital, an asset management firm nominated twice for Emerging Manager of the Year by S&amp;P’s Capital IQ.</p>
              <p>Previously, he was a Managing Director and Global coHead of FX Structuring at Barclays Capital until 2008. He started his career with Merrill Lynch in New York focusing on M&amp;A /Financings, subsequently responsible for the European Structured business.</p>
              <p>Philippos holds a BA in Engineering from Oxford University and MA Economics / MBA Finance degrees from Yale University. He sits on the boards of Brookstreet Equity Partners and YPO London and is the Deputy Director of the Centre for Innovation and Disruption at Cass Business School.</p>
          `
        },
        {
          Title   : 'RITIKA DHAMIJA',
          Subtitle: 'Director',
          Image   : '/img/about/ritika-dhamija-300x300.jpg',
          Body    : `
              <p>Ritika is a Director of Maven Synthesis. She is also a co-founder of Iqrup + Ritz, a high growth furniture brand and a partner at Maven Global. Previously, Ritika was a Vice President at the Foreign Exchange division of Barclays Capital, the investment banking arm of Barclays Group.</p>
              <p>Ritika holds a BA and MA in Physics from Oxford University.  She also sits on the advisory board and panel of judges for Oxford & Cambridge Artificial Intelligence competitions.</p>
          `
        },
        {
          Title   : 'MANOS VENARDOS',
          Subtitle: 'Advisor',
          Image   : '/img/about/manos-venardos.png',
          Body    : `
              <p>Manos was the Global Head of Quantitative Analytics at the UBS Investment Bank from 2012 to 2023. In his role, he managed the US, EMEA and APAC team with a focus on pricing models flow and structured products across all asset classes, as well as developing the Firm's risk management platforms. Previously, Manos held leadership roles in the Equity Derivatives Quant space for Nomura (2010-2012) and JPMorgan (2001-2010).</p>
              <p>Manos is also an Associate Fellow at the Said Business School, University of Oxford. He holds a DPhil in derivatives pricing and MPhil in Economics from the University of Oxford, and a BA in Economics from the University of Cambridge.</p>
          `
        },
        {
          Title   : 'TOM NICHOLAS',
          Subtitle: 'Advisor, William J. Abernathy Professor of Business Administration, Harvard Business School',
          Image   : '/img/about/tom-nicholas.jpg',
          Body    : `
              <p>Tom Nicholas is a Professor in the Entrepreneurial Management Group of Harvard Business School. He holds a doctorate in Economic History from Oxford University. Prior to joining HBS, he taught Technology Strategy at MIT’s Sloan School of Management and technology and finance courses at the London School of Economics. He was also an economics consultant in San Francisco where he performed economic analysis for environmental and antitrust litigation including Sun Microsystems v. Microsoft. At HBS he has taught the first year course, The Entrepreneurial Manager, and he currently teaches in Executive Education programs on entrepreneurship and intellectual property as well as two second year elective courses: The Coming of Managerial Capitalism, which examines entrepreneurship, innovation and business development in the United States over the past 230 years; and Venture Capital in Historical Perspective (with Felda Hardymon), which focuses on the changing organizational structure of the venture capital industry and its impact on entrepreneurship and innovation over time.</p>
              <p>He has received the Faculty Teaching Award in both the Required Curriculum and the Elective Curriculum and the Charles M. Williams Award for teaching excellence.</p>
          `
        },
        {
          Title   : 'RICARDO CERVERA',
          Subtitle: 'Advisor, Founding Partner, Vace Partners',
          Image   : '/img/about/ricardo-cervera.jpg',
          Body    : `
              <p>Ricardo Cervera is a Founding Partner at VACE Partners. Prior to founding VACE, Ricardo worked for over 9 years at Salomon Smith Barney and Citigroup.</p>
              <p>Ricardo joined the Investment Banking Division of Salomon Smith Barney in 1999 as a summer associate and then full-time in August 2000. He spent seven years in New York in the Global Power Group, covering US, European and Latin American power companies. Mr. Cervera moved to Citigroup’s Mexico Investment Banking Office as a Director in 2006, and became Head of Mexico Investment Banking in 2007. Ricardo had coverage responsibilities in the power, energy, infrastructure, consumer, retail, industrial and other sectors. Upon arriving to Mexico, he led the privatization of the first Toll-Roads from FARAC worth over US$4 billion; one of the largest privatizations ever in this sector worldwide and winner of Project Finance Latam Deal of the Year in 2008.</p>
              <p>Ricardo currently serves on the Board of Directors of Office Depot Mexico and Concentradora Hipotecaria. From 1995 to 1996, Ricardo worked in Mexico’s Ministry of Finance as an advisor to the General Director of Public Credit and in 1997 became advisor to the Deputy Minister of Finance. Ricardo graduated in the top 1% of his class with a B.A. in Economics from the Instituto Tecnológico Autónomo de México (ITAM) and received an M.B.A. from Yale University. In 1998, Ricardo received Mexico’s National Award in Economic Research.</p>
          `
        }
      ]
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
