/* IMPORTS START */
import ApiResponseEnum from '@/api/enums/ApiResponseEnum'
import Http            from '@/lib/data/api/Http'
import Contact         from '@/api/endpoint/Contact'
/* IMPORTS END */

export const API = {
  get RESTRequest () {
    return Http.Request
  },
  get RESTRequestAll () {
    return Http.RequestAll
  },

  get Resource () {
    return {
      /* RESOURCES */
      Contact: Contact
    }
  },

  get Endpoint () {
    return {
      /* ENDPOINTS */
      Contact: {
        SubmitForm: {
          Path  : 'api/submitInquiry',
          Method: Http.Method.Post
        }
      }
    }
  },

  responseData     : (response) => response?.data || null,
  responseErrors   : (response) => response?.data?.violations || null,
  isResponseSuccess: (response) => response?.status === ApiResponseEnum.SUCCESS && API.responseData(response) && !API.responseErrors(response)
}

export { default as Http } from '../lib/data/api/Http'
export default API
