<template>
  <v-container
    v-if="article"
    fluid
  >
    <v-row v-if="article.featured_media && getArticleImage(article, 'full')">
      <v-col
        class="text-center"
        cols="12"
      >
        <v-img
          :gradient="'to bottom, rgba(0,0,0,0) 75%, rgb(208, 217, 245, 1)'"
          :src="getArticleImage(article, 'full').source_url"
          class="rounded-t-xxl rounded-b-0"
          max-height="600"
        />

        <v-card
          class="synthesis-ui-blue-07 pa-4 pa-md-8 pa-lg-8 rounded-t-0 rounded-b-xxl"
          color="transparent"
          flat
        >
          <v-container
            class="synthesis-brand-dark-blue--text text-left"
            fluid
          >
            <v-row>
              <v-col
                cols="12"
                lg="6"
                md="8"
                offset-lg="1"
                offset-xl="1"
                xl="7"
              >
                <v-card
                  class="mb-8"
                  flat
                >
                  <v-card-text>
                    <v-chip
                      class="ma-4 white--text float-right mt-6"
                      color="synthesis-ui-blue-01"
                      label
                      small
                    >
                      {{ getArticleDate(article) }}
                    </v-chip>

                    <div
                      :class="sectionTitleClass"
                      class="py-4 synthesis-brand-dark-blue--text text-left font-weight-regular"
                      v-html="sanitizeText(article.title.rendered)"
                    />

                    <div
                      :class="sectionSubTitleClass"
                      class="synthesis-brand-dark-blue--text text-left font-weight-regular"
                      v-html="sanitizeText(article.content.rendered)"
                    />
                  </v-card-text>
                </v-card>

                <v-card
                  class="transparent"
                  flat
                >
                  <v-card-title class="pl-1">
                    Related Posts
                  </v-card-title>

                  <v-container
                    class="pa-1"
                    fluid
                  >
                    <v-row>
                      <template v-for="(relatedArticle, relatedArticleIdx) in relatedArticles">
                        <v-col
                          :key="`related-article-${relatedArticleIdx}`"
                          cols="12"
                          sm="6"
                        >
                          <v-card
                            :to="{name: 'PressArticle', params: {slug: relatedArticle.slug}}"
                            flat
                          >
                            <v-img
                              v-if="relatedArticle.featured_media && getArticleImage(relatedArticle, 'full')"
                              :src="getArticleImage(relatedArticle, 'full').source_url"
                              height="250"
                            />

                            <v-card-text style="min-height: 85px;">
                              <v-chip
                                class="white--text float-right mt-2"
                                color="synthesis-ui-blue-01"
                                label
                                small
                              >
                                {{ getArticleDate(relatedArticle) }}
                              </v-chip>

                              <div
                                class="py-1 synthesis-section-subtitle synthesis-brand-dark-blue--text text-left font-weight-regular"
                                v-html="sanitizeText(relatedArticle.title.rendered)"
                              />
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>

              <v-col
                v-if="$vuetify.breakpoint.mdAndUp"
                lg="4"
                md="4"
                xl="3"
              >
                <recent-articles articles-count="10" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta     from '@/mixins/i18n/i18nRouteMeta'
import CssClassFontSizes from '@/mixins/cssClassFontSizes'
import PressCommon       from '@/mixins/pressCommon'
import RecentArticles    from '@/theme/default/views/press/RecentArticles.vue'
import { clone }         from '@/lib/utils/helper'

export default {
  name      : 'PressArticle',
  components: { RecentArticles },
  directives: {},
  mixins    : [i18nRouteMeta, CssClassFontSizes, PressCommon],
  props     : {
    slug: {
      type   : String,
      default: ''
    }
  },
  dataStore: {
    PressArticles: 'Press.Articles'
  },
  enums: {},
  data () {
    return {
      article: null
    }
  },
  computed: {
    relatedArticles () {
      return this.shuffleArticles(this.slug)?.slice(0, 2) || []
    }
  },
  watch: {
    slug () {
      this.setArticle()
    },
    PressArticles () {
      this.setArticle()
    }
  },
  beforeCreate () {},
  created () {
    this.setArticle()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    setArticle () {
      this.article = this.getArticleBySlug(this.slug)
    },

    shuffleArticles () {
      const array = clone(this.PressArticles)

      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]
      }
      return array
    }
  }
}
</script>

<style scoped>

</style>
