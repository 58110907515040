export default {
  name    : 'CssClassFontSizes',
  computed: {
    headerTitleClass () {
      return {
        'synthesis-header-title'   : this.$vuetify.breakpoint.smAndDown,
        'synthesis-header-title-md': this.$vuetify.breakpoint.mdAndUp,
        'synthesis-header-title-lg': this.$vuetify.breakpoint.lgAndUp
      }
    },
    headerSubTitleClass () {
      return {
        'synthesis-header-subtitle'   : this.$vuetify.breakpoint.smAndDown,
        'synthesis-header-subtitle-md': this.$vuetify.breakpoint.mdAndUp
      }
    },

    sectionTitleClass () {
      return {
        'synthesis-section-title'   : this.$vuetify.breakpoint.smAndDown,
        'synthesis-section-title-md': this.$vuetify.breakpoint.mdAndUp,
        'synthesis-section-title-lg': this.$vuetify.breakpoint.lgAndUp
      }
    },
    sectionSubTitleClass () {
      return {
        'synthesis-section-subtitle'   : this.$vuetify.breakpoint.smAndDown,
        'synthesis-section-subtitle-md': this.$vuetify.breakpoint.mdAndUp
      }
    }
  }
}
