<template>
  <v-app>
    <app-toolbar />

    <v-main class="synthesis-brand-dark-blue">
      <v-container
        class="pa-0 pa-sm-3"
        fluid
      >
        <v-row dense>
          <v-col cols="12">
            <slot />
          </v-col>
        </v-row>
      </v-container>

      <contact-dialog
        :visible.sync="contactDialogVisible"
        @dialog:save:success="onContactSuccess"
      />
    </v-main>

    <app-footer />
  </v-app>
</template>

<script>
import AppToolbar        from '@/theme/default/components/app/AppToolbar'
import AppFooter         from '@/theme/default/components/app/AppFooter'
import { themeUtils }    from '@/lib/utils'
import CssClassFontSizes from '@/mixins/cssClassFontSizes'

const ContactDialog = () => themeUtils.importThemeComponent('views/contact/ContactDialog')

export default {
  name      : 'DefaultLayout',
  components: {
    AppFooter,
    AppToolbar,
    ContactDialog
  },
  mixins   : [CssClassFontSizes],
  dataStore: {},
  data () {
    return {
      contactDialogVisible: false
    }
  },
  computed: {},
  watch   : {},
  created () {
    this.$bus.$on('Dialog:Contact:Show', this.showContactDialog)
  },
  mounted () {},
  beforeDestroy () {
    this.$bus.$off('Dialog:Contact:Show', this.showContactDialog)
  },
  methods: {
    showContactDialog () {
      this.contactDialogVisible = true
    },

    onContactSuccess () {
      // eslint-disable-next-line no-console
      console.log('>>> onContactSuccess')
    }
  }
}

</script>

<style>

</style>
