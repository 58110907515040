import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class ContactValidator extends BaseValidator {
  validatorRules = {
    contactName: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2
    },
    contactNumber: {
      required  : false,
      type      : BaseValidator.Type.Numeric,
      betweenLen: [10, 14]
    },
    email: {
      required: false,
      type    : BaseValidator.Type.Email
    },
    subject: {
      required: false,
      type    : BaseValidator.Type.String,
      minLen  : 3
    },
    message: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 10
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
