<template>
  <div class="">
    <v-app-bar
      app
      class="px-0 px-md-2"
      clipped-left
      color="synthesis-brand-dark-blue"
      dark
      dense
      flat
      height="80"
    >
      <div class="d-flex align-center fill-height">
        <router-link :to="{name: 'Home'}">
          <img
            :height="$vuetify.breakpoint.smAndDown ? 36 : 40"
            :src="$vuetify.breakpoint.smAndDown ? '/img/logo/logo-symbol.png' : '/img/logo/logo-light.png'"
          >
        </router-link>
      </div>

      <div class="flex-grow-1" />

      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
          :height="$vuetify.breakpoint.smAndDown ? 36 : 42"
          :min-width="$vuetify.breakpoint.xsOnly ? 'auto' : 90"
          :to="{name: 'Home'}"
          class="synthesis-brand-dark-blue--text- px-2 mr-2"
          color="white"
          depressed
          exact
          outlined
          v-text="$t('Common.Button.Home')"
        />

        <v-btn
          :height="$vuetify.breakpoint.smAndDown ? 36 : 42"
          :min-width="$vuetify.breakpoint.xsOnly ? 'auto' : 90"
          :to="{name: 'AboutUs'}"
          class="synthesis-brand-dark-blue--text- px-2 mr-2"
          color="white"
          depressed
          outlined
          v-text="$t('Common.Button.AboutUs')"
        />

        <v-btn
          :height="$vuetify.breakpoint.smAndDown ? 36 : 42"
          :min-width="$vuetify.breakpoint.xsOnly ? 'auto' : 90"
          :to="{name: 'Press'}"
          class="synthesis-brand-dark-blue--text- px-2 mr-2"
          color="white"
          depressed
          outlined
          v-text="$t('Common.Button.Press')"
        />

        <v-btn
          :height="$vuetify.breakpoint.smAndDown ? 36 : 42"
          :min-width="$vuetify.breakpoint.xsOnly ? 'auto' : 110"
          class="white--text px-2 mr-2"
          color="synthesis-ui-green-02"
          depressed
          href="https://app.mavensynthesis.com/"
          target="_blank"
          v-text="$t('Common.Button.Login')"
        />

        <v-btn
          :height="$vuetify.breakpoint.smAndDown ? 36 : 42"
          :min-width="$vuetify.breakpoint.xsOnly ? 'auto' : 110"
          class="synthesis-brand-dark-blue--text px-2 mr-2"
          color="white"
          depressed
          @click="$bus.$emit('Dialog:Contact:Show')"
          v-text="$t('Common.Button.GetStarted')"
        />
      </template>

      <template v-else>
        <v-menu
          min-width="125"
          offset-y
        >
          <template #activator="{on}">
            <v-app-bar-nav-icon
              color="synthesis-ui-blue-06"
              v-on="on"
            />
          </template>

          <v-list
            color="synthesis-ui-blue-02 text-center"
            dark
            nav
          >
            <v-list-item
              :to="{name: 'Home'}"
              exact
            >
              <v-list-item-content>
                <v-list-item-title v-text="$t('Common.Button.Home')" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{name: 'AboutUs'}">
              <v-list-item-content>
                <v-list-item-title v-text="$t('Common.Button.AboutUs')" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{name: 'Press'}">
              <v-list-item-content>
                <v-list-item-title v-text="$t('Common.Button.Press')" />
              </v-list-item-content>
            </v-list-item>

            <v-divider class="mb-2" />

            <v-list-item
              class="synthesis-ui-green-02"
              href="https://app.mavensynthesis.com/"
              target="_blank"
            >
              <v-list-item-content>
                <v-list-item-title v-text="$t('Common.Button.Login')" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="white synthesis-brand-dark-blue--text"
              @click="$bus.$emit('Dialog:Contact:Show')"
            >
              <v-list-item-content>
                <v-list-item-title v-text="$t('Common.Button.GetStarted')" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
  </div>
</template>

<script>

export default {
  name      : 'AppToolbar',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  dataStore : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding-top    : 0;
  padding-bottom : 0;
  height         : 100% !important;
}
</style>
