<template>
  <v-footer
    class="align-start white--text text-size-14"
    color="synthesis-brand-dark-blue"
  >
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <img
            height="25"
            src="/img/logo/logo-light.png"
          >
        </v-col>

        <v-col
          class="text-left text-md-right"
          cols="8"
          md="2"
          sm="5"
        >
          <div class="text-left d-inline-block">
            <div class="pa-1 font-weight-bold">
              Contact
            </div>

            <div class="pa-1">
              <a href="mailto:info@mavensynthesis.com">
                info@mavensynthesis.com
              </a>
            </div>

            <div class="pa-1">
              <a href="tel:+442081660064">
                +44 208 166 0064
              </a>
            </div>

            <div class="pa-1">
              <a
                href="https://maps.app.goo.gl/xiwqcNdcazmXzhZp8"
                target="_blank"
              >
                17 Grosvenor Street<br>
                London W1K 4QG, UK<br>
              </a>
            </div>

            <div class="pa-1 mt-2">
              <a
                href="https://www.linkedin.com/company/mavensynthesis/"
                target="_blank"
              >
                <img
                  class="rounded"
                  src="/img/social/follow-us-on-linkedin.png"
                  width="150"
                >
              </a>
            </div>
          </div>
        </v-col>

        <v-col
          class="text-left text-md-right"
          cols="4"
          md="2"
          sm="5"
        >
          <div class="text-left d-inline-block">
            <div class="pa-1 font-weight-bold">
              Information
            </div>

            <div class="pa-1">
              <router-link :to="{name: 'AboutUs'}">
                About Us
              </router-link>
            </div>

            <div class="pa-1">
              <router-link :to="{name: 'Press'}">
                Press
              </router-link>
            </div>

            <div class="pa-1">
              <a
                href="/doc/maven-synthesis-disclaimers.pdf"
                target="_blank"
              >
                Disclaimers
              </a>
            </div>

            <div class="pa-1">
              <a
                href="https://mavenglobal.co.uk/"
                target="_blank"
              >
                Maven Global
              </a>
            </div>

            <div class="pa-1">
              <a
                href="#"
                @click.prevent="$bus.$emit('Dialog:Contact:Show')"
              >
                Get Started
              </a>
            </div>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="2"
          sm="2"
        >
          <div class="pa-2 text-center text-md-right mr-1">
            <img
              src="/img/award/soe.png"
              width="92"
            >
          </div>

          <div
            class="py-3 text-center text-md-right"
            style="font-size: 11px;"
          >
            <a
              href="https://otodev.gr/"
              target="_blank"
              title="Developed By OTODEV"
            >Developed By OTODEV</a>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="justify-center align-center"
          cols="12"
        >
          <v-img src="/img/bg/footer-bg.png" />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>

export default {
  name      : 'AppFooter',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
a {
  color           : white;
  text-decoration : none;
}

a:link {
  color           : white;
  text-decoration : none;
}

a:visited {
  color           : white;
  text-decoration : none;
}

a:focus {
  color           : white;
  text-decoration : none;
}

a:hover {
  opacity         : 0.75;
  color           : white;
  text-decoration : none;
}

a:active {
  color           : white;
  text-decoration : none;
}
</style>
