const ApiResponseEnum = {
  SUCCESS              : 200,
  ERROR                : 400,
  UNAUTHORIZED         : 401,
  FORBIDDEN            : 403,
  NOTFOUND             : 404,
  GENERIC_ERROR        : 422,
  VALIDATOR_ERROR      : 423,
  FORCE_CHANGE_PASSWORD: 424,
  FORCE_2FA            : 425
}

export default ApiResponseEnum
