import axios from 'axios'

export default {
  name: 'PressCommon',
  data () {
    return {}
  },
  computed: {},
  created () {
    this.getData()
  },
  methods: {
    sanitizeText (text) {
      const regex = /((\[(.*?)\])|(\[\/(.*?)\]))/gm

      return text.replaceAll(regex, '')
    },

    getArticleBySlug (slug) {
      return this.PressArticles?.find(article => article.slug === slug) || null
    },

    getArticleImage (article, size = 'large') {
      const featuredMediaId = article.featured_media
      const media = article?._embedded['wp:featuredmedia']?.find(media => media.id === featuredMediaId) || null

      return media?.media_details?.sizes[size] || media?.media_details?.sizes?.full || ''
    },

    getArticleDate (article) {
      if (!article) return ''

      const dateStr = article?.date || ''
      const [year, month, day] = dateStr.substring(0, 10)?.split('-')

      return `${ String(day).padStart(2, '0') }/${ String(month).padStart(2, '0') }/${ year }`
    },

    getArticleTime (article) {
      if (!article) return ''

      const dateStr = article?.date || ''
      const [hour, minute] = dateStr.substring(11, dateStr.length)?.split(':')

      return `${ String(hour).padStart(2, '0') }:${ String(minute).padStart(2, '0') }`
    },

    async getData () {
      if (this.PressArticles?.length) return

      const url = '/press/press.json'
      // https://nordicapis.com/10-free-to-use-cors-proxies/
      // const url = 'https://corsproxy.io/?' + encodeURIComponent('https://mavenglobal.co.uk/wp-json/wp/v2/posts/?_embed&orderby=date&order=desc&page=1&per_page=100')
      // const url = 'https://thingproxy.freeboard.io/fetch/' + encodeURIComponent('https://mavenglobal.co.uk/wp-json/wp/v2/posts/?_embed&orderby=date&order=desc&page=1&per_page=100')
      const config = {
        headers: {
          'Access-Control-Allow-Origin'  : '*',
          'Access-Control-Expose-Headers': 'x-wp-total'
        }
      }

      const result = await axios.get(url, config)
      this.PressArticles = result?.data || []
    }
  }
}
